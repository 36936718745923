import { Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EPL_RTRUCK from 'assets/images/landing/EPL_RTRUCK.jpg';
import { gridSpacing } from 'store/constant';

export default function BookAppointment() {
    const theme = useTheme();
    return (
        <Container>
            <Grid container spacing={gridSpacing} sx={{ pt: 10 }}>
                <Grid item xs={12} lg={6} md={6}>
                    <Typography variant="h2" component="div">
                        <span style={{ color: theme.palette.secondary.main }}>Tailored</span> To Fit Any Fleet
                    </Typography>
                    <Typography variant="body2">
                        Our services can also be tailored to fit your business model, vehicle types, fuel type, and the routes and regions
                        your vehicles cover. Using fuel cards for your transport company allows you to control how much you spend per
                        vehicle, thus streamlining your operations and removing any bottlenecks. Your drivers will never be left stranded at
                        the pump or in the field ever again.
                    </Typography>

                    {/* <br />
                    <br />
                    <Typography variant="h2" component="div">
                        Book <span style={{ color: theme.palette.secondary.main }}>An Appointment</span>
                    </Typography>
                    <Typography variant="body2">Book an appointment with our employees to know about the deals</Typography>
                    <AnimateButton>
                        <Button
                            sx={{ mt: 2 }}
                            // component="a"
                            // href="https://calendly.com/eplsupport/appointment"
                            onClick={() => {
                                window.Calendly.initPopupWidget({ url: 'https://calendly.com/eplsupport' });
                            }}
                            size="large"
                            variant="contained"
                            color="secondary"
                            // target="_blank"
                        >
                            Book Now
                        </Button>
                    </AnimateButton> */}
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                    <img
                        src={EPL_RTRUCK}
                        alt="truck"
                        style={{
                            width: '100%',
                            borderRadius: '8px',
                            height: '100%'
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
