import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    wheatherInfo: undefined,
    wheatherLoading: false
};

// ==============================|| SLICE - MENU ||============================== //

const Wheather = createSlice({
    name: 'Wheather',
    initialState,
    reducers: {
        setWheatherData(state, action) {
            state.wheatherInfo = action.payload;
        },
        setWheatherLoading(state, action) {
            state.wheatherLoading = action.payload;
        }
    }
});

export default Wheather.reducer;
export const { setWheatherData, setWheatherLoading } = Wheather.actions;
