/* eslint-disable prettier/prettier */
// project imports
// assets
import MenuIcon from "@mui/icons-material/Menu";
// material-ui
import {
  AppBar as MuiAppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  IconHome2,
  IconLogin,
  IconNews,
  IconPhoneOutgoing,
  IconFlower,
} from "@tabler/icons";
import useOffSetTop from "hooks/useOffSetTop";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

function ElevationScroll({ children, window }) {
  const theme = useTheme();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });
  const darkBorder =
    theme.palette.mode === "dark"
      ? theme.palette.dark.dark
      : theme.palette.grey[200];

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
    style: {
      // backgroundColor: theme.palette.background.default,
      borderBottom: trigger ? "none" : "1px solid",
      borderColor: trigger ? "" : darkBorder,
      color: theme.palette.text.dark,
    },
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.node,
  window: PropTypes.object,
};

// ==============================|| MINIMAL LAYOUT APP BAR ||============================== //

const AppBar = ({ ...others }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const [drawerToggle, setDrawerToggle] = React.useState(false);
  const isOffset = useOffSetTop(100);
  /** Method called on multiple components with different event types */
  const drawerToggler = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerToggle(open);
  };

  let navItemColor = "";
  const titleColor =
    location.pathname === "/" ||
    location.pathname === "/news" ||
    location.pathname === "/about" ||
    location.pathname === "/privacy-policy" ||
    isOffset
      ? theme.palette.primary.main
      : theme.palette.common.white;

  if (!isOffset) {
    if (
      location.pathname === "/" ||
      location.pathname === "/news" ||
      location.pathname === "/about" || 
      location.pathname === "/privacy-policy"
    )
      navItemColor = theme.palette.getContrastText(theme.palette.common.white);
    else
      navItemColor = theme.palette.getContrastText(theme.palette.common.black);
  }

  return (
    <ElevationScroll {...others}>
      <MuiAppBar color={isOffset ? "inherit" : "transparent"}>
        <Container>
          <Toolbar style={{ padding: "0px" }}>
            <Box sx={{ flexGrow: 1, textAlign: "left" }}>
              <Link
                sx={{
                  textDecoration: "none",
                  ":hover": {
                    cursor: "pointer",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  src="logo192.png"
                  alt="eco petrochemical"
                  width="80"
                  style={{ marginTop: 8 }}
                />
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1,
                    color: titleColor,
                  }}
                >
                  Eco Petroleum
                </Typography>
              </Link>
            </Box>
            <Stack
              direction="row"
              sx={{ display: { xs: "none", sm: "block" } }}
              spacing={2}
            >
              <Button
                color="inherit"
                component={Link}
                onClick={() => {
                  navigate("/");
                }}
                sx={{ color: navItemColor }}
              >
                Home
              </Button>
              <Button
                color="inherit"
                component={Link}
                onClick={() => {
                  navigate("/news");
                }}
                sx={{ color: navItemColor }}
              >
                News
              </Button>
              <Button
                color="inherit"
                component={Link}
                onClick={() => {
                  navigate("/contact");
                }}
                sx={{ color: navItemColor }}
              >
                Contact us
              </Button>
              <Button
                color="inherit"
                component={Link}
                onClick={() => {
                  navigate("/about");
                }}
                sx={{ color: navItemColor }}
              >
                About us
              </Button>
              <Button
                component={Link}
                disableElevation
                variant="contained"
                color="secondary"
                href="https://portal.ecopetroleum.ca/#/login"
              >
                Login
              </Button>
            </Stack>
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <IconButton
                color="inherit"
                onClick={drawerToggler(true)}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="top"
                open={drawerToggle}
                onClose={drawerToggler(false)}
              >
                {drawerToggle && (
                  <Box
                    sx={{ width: "auto" }}
                    role="presentation"
                    onClick={drawerToggler(false)}
                    onKeyDown={drawerToggler(false)}
                  >
                    <List>
                      <Link
                        style={{ textDecoration: "none" }}
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        <ListItemButton component="a">
                          <ListItemIcon>
                            <IconHome2 />
                          </ListItemIcon>
                          <ListItemText primary="Home" />
                        </ListItemButton>
                      </Link>
                      <Link
                        style={{ textDecoration: "none" }}
                        onClick={() => {
                          navigate("/news");
                        }}
                      >
                        <ListItemButton component="a">
                          <ListItemIcon>
                            <IconNews />
                          </ListItemIcon>
                          <ListItemText primary="News" />
                        </ListItemButton>
                      </Link>
                      <Link
                        style={{ textDecoration: "none" }}
                        onClick={() => {
                          navigate("/contact");
                        }}
                      >
                        <ListItemButton component="a">
                          <ListItemIcon>
                            <IconPhoneOutgoing />
                          </ListItemIcon>
                          <ListItemText primary="Contact us" />
                        </ListItemButton>
                      </Link>
                      <Link
                        style={{ textDecoration: "none" }}
                        onClick={() => {
                          navigate("/about");
                        }}
                      >
                        <ListItemButton component="a">
                          <ListItemIcon>
                            <IconFlower />
                          </ListItemIcon>
                          <ListItemText primary="About us" />
                        </ListItemButton>
                      </Link>
                      <Link
                        style={{ textDecoration: "none" }}
                        href="https://portal.ecopetroleum.ca/#/login"
                        // onClick={() => {
                        //   navigate("/login");
                        // }}
                      >
                        <ListItemButton component="a">
                          <ListItemIcon>
                            <IconLogin />
                          </ListItemIcon>
                          <ListItemText primary="Login" />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Box>
                )}
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </MuiAppBar>
    </ElevationScroll>
  );
};

export default AppBar;
