/* eslint-disable react/no-unescaped-entities */
import { useTheme } from '@emotion/react';
import { Container, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { gridSpacing } from 'store/constant';

export default function About() {
    const theme = useTheme();
    return (
        <>
            <Container>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                                <Typography variant="h1" component="div">
                                    About Us
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    We are modernized company providing services for transport companies across North America. We understand
                                    that every dollar makes a difference, so we help our clients, both large and small fleet owners, save
                                    thousands yearly. We do this by providing access to data-driven decisions, AI Route Planner and
                                    recommendations using cutting edge technology. Our core service is reinforced by an array of fleet
                                    solutions from tax reporting to weather reports - all managed from our online portal. Our team includes
                                    experts curated from several fields such as tech, accounting, and the petroleum industry, as well as
                                    independent contractors whose main goal is to serve you, our clients.
                                </Typography>
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                                <Typography variant="h2" component="div">
                                    Why We <span style={{ color: theme.palette.secondary.main }}>Exist</span>?
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    Optimizing fleets is the heart of everything we do as as your trusted partner. We pride ourselves in
                                    providing advanced solutions to ensure success for clients. Additionally, we ensure our services fit
                                    with your business, no matter your needs. We understand that when a transport company chooses us, we
                                    become an integral part of their workflow and day-to-day management. Essentially, they out-source their
                                    fuel management to us, allowing them to work more efficiently. Thus, it is more than just hitting
                                    short-term goals but building long-term value-filled relationships for us.
                                </Typography>
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                                <Typography variant="h2" component="div">
                                    How We <span style={{ color: theme.palette.secondary.main }}>Operate</span>?
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    It does not matter whether your business needs a single-card or multi-card system to complement its
                                    operational structure. Dashboards can be customized to match your organization's needs. Our team will
                                    work hand-in-hand with you to ensure that we provide the best possible service for your company. We do
                                    this while keeping in mind that our relationship with your business is dynamic. So as your business
                                    changes and workflow evolves, we will make recommendations to help you save.
                                </Typography>
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
