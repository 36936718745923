// import { Link } from 'react-router-dom';
// material-ui
import { Button, Card, CardContent, Container, FormControl, Grid, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
// third-party
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'store';
// project imports
import { gridSpacing } from 'store/constant';
import { openSnackbar } from 'store/slices/snackbar';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { contactUsURL } from '../../../config';

// assets

// select options

// ===========================|| CONTACT CARD - FORMS ||=========================== //

const ContactCard = () => {
    const [fullName, setFullName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [EmailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const theme = useTheme();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const onContactSave = async (e) => {
        try {
            e.stopPropagation();
            e.preventDefault();

            const requestURL = `${contactUsURL}?fullName=${fullName}&companyName=${companyName}&EmailAddress=${EmailAddress}&phoneNumber=${phoneNumber}&message=${message}`;

            const newPage = window.open(requestURL);
            setTimeout(() => {
                newPage.close();
                navigate('/');

                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Success! We send your query to our expert.',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
            }, 1000);
        } catch (ex) {
            console.log(ex);
        }
    };

    return (
        <Container>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item sm={10} md={7} xs={12} sx={{ mt: { md: 12.5, xs: 2.5 } }}>
                    <Typography
                        variant="h1"
                        color="white"
                        component="div"
                        sx={{
                            fontSize: '3.5rem',
                            fontWeight: 900,
                            lineHeight: 1.4,
                            [theme.breakpoints.down('md')]: { fontSize: '1.8125rem', marginTop: '80px' }
                        }}
                    >
                        Talk to our expert
                    </Typography>
                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ fontWeight: 400, lineHeight: 1.4, [theme.breakpoints.up('md')]: { my: 0, mx: 12.5 } }}
                        color="white"
                    >
                        {/* The starting point for your next project based on easy-to-customize Material-UI © helps you build apps faster and
                        better. */}
                    </Typography>
                </Grid>
                <Grid item xs={10} sx={{ mb: 17.5 }}>
                    <form onSubmit={onContactSave} action={contactUsURL} method="any">
                        <Card sx={{ mb: 6.25 }} elevation={4}>
                            <CardContent sx={{ p: 4 }}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Full Name</InputLabel>
                                            <OutlinedInput
                                                value={fullName}
                                                onChange={(e) => setFullName(e.target.value)}
                                                type="text"
                                                label="Name"
                                                name="fullName"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Company Name</InputLabel>
                                            <OutlinedInput
                                                value={companyName}
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                type="text"
                                                label="Company Name"
                                                name="companyName"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Email Address</InputLabel>
                                            <OutlinedInput
                                                value={EmailAddress}
                                                onChange={(e) => setEmailAddress(e.target.value)}
                                                type="text"
                                                label="Email Address"
                                                name="EmailAddress"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <NumberFormat
                                                value={phoneNumber}
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                                format="+1 (###) ###-####"
                                                mask="_"
                                                fullWidth
                                                customInput={TextField}
                                                label="Phone Number"
                                                name="phoneNumber"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                                id="outlined-multiline-static1"
                                                placeholder="Message"
                                                multiline
                                                fullWidth
                                                rows={4}
                                                name="message"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={gridSpacing}>
                                            <Grid item sm zeroMinWidth>
                                                {/* <Typography align="left" variant="body2">
                                                    By submitting this, you agree to the
                                                    <Typography
                                                        variant="subtitle1"
                                                        component={Link}
                                                        to="#"
                                                        color="primary"
                                                        sx={{ mx: 0.5 }}
                                                    >
                                                        Privacy Policy
                                                    </Typography>
                                                    and
                                                    <Typography
                                                        variant="subtitle1"
                                                        component={Link}
                                                        to="#"
                                                        color="primary"
                                                        sx={{ ml: 0.5 }}
                                                    >
                                                        Cookie Policy
                                                    </Typography>
                                                </Typography> */}
                                            </Grid>
                                            <Grid item>
                                                <AnimateButton>
                                                    <Button variant="contained" color="secondary" type="submit">
                                                        Submit Data
                                                    </Button>
                                                </AnimateButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </form>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ContactCard;
