// material-ui
import { Button, Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// third party
import { motion } from 'framer-motion';
// project imports
// project imports
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';

// styles

// ==============================|| LANDING - HEADER PAGE ||============================== //

const HeaderPage = () => {
    const theme = useTheme();

    return (
        <Container>
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={gridSpacing}
                sx={{ mt: { xs: 10, sm: 6, md: 18.75 }, mb: { xs: 2.5, md: 10 } }}
            >
                <Grid item xs={12} md={10}>
                    <Grid container spacing={gridSpacing} sx={{ pr: 10, [theme.breakpoints.down('lg')]: { pr: 0, textAlign: 'center' } }}>
                        <Grid item xs={12}>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    sx={{
                                        fontSize: { xs: '2.25rem', sm: '3rem', md: '4rem' },
                                        fontWeight: 900
                                        // lineHeight: 1.4
                                    }}
                                >
                                    Welcome to world of
                                </Typography>
                                <Typography
                                    variant="h1"
                                    sx={{
                                        fontSize: { xs: '2.25rem', sm: '3rem', md: '4rem' },
                                        fontWeight: 900,
                                        color: theme.palette.primary.main
                                    }}
                                >
                                    Eco Petroleum
                                </Typography>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12}>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 0.2
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    component="div"
                                    color="inherit"
                                    sx={{
                                        // fontSize: { xs: '1rem' },
                                        fontWeight: 400,
                                        lineHeight: 1.3,
                                        color: 'black'
                                    }}
                                >
                                    We are a full-service company providing the best solutions for transportation companies and fleets in
                                    North America. Whether you’re a building transport company or an enterprise with large fleets, you can
                                    start getting access to wide variery of services empowered by Modern Technology once you sign up. We
                                    remain focused on giving you the best customer experience with our customized solutions. It’s easy to
                                    start or switch today.
                                </Typography>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} sx={{ my: 3.25 }}>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 0.4
                                }}
                            >
                                <Grid container spacing={2} sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}>
                                    <Grid item>
                                        <AnimateButton>
                                            <Button
                                                onClick={() => {
                                                    window.open('https://portal.ecopetroleum.ca/#/login');
                                                }}
                                                // to=""
                                                size="large"
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Login
                                            </Button>
                                        </AnimateButton>
                                    </Grid>
                                </Grid>
                            </motion.div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default HeaderPage;
