import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import Loadable from 'ui-component/Loadable';
import MainRoutes from './MainRoutes';

const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));
const News = Loadable(lazy(() => import('views/pages/News/index')));
const Contact = Loadable(lazy(() => import('views/pages/contact-us/index')));
const About = Loadable(lazy(() => import('views/pages/About/index')));
const PrivacyPolicy = Loadable(lazy(() => import('views/pages/PrivacyPolicy/index')));
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        { path: '/', element: <PagesLanding /> },
        { path: '/news', element: <News /> },
        { path: '/contact', element: <Contact /> },
        { path: '/about', element: <About /> },
        { path: '/privacy-policy', element: <PrivacyPolicy /> },
        MainRoutes
    ]);
}
