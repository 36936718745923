// project imports
import About from 'views/pages/About/About';
import ContactCard from 'views/pages/contact-us/ContactCard';
import Landing from 'views/pages/landing';
import Error from 'views/pages/maintenance/Error';
import News from 'views/pages/News/News';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    children: [
        {
            path: '/',
            element: <Landing />
        },
        {
            path: '/news',
            element: <News />
        },
        {
            path: '/contact',
            element: <ContactCard />
        },
        {
            path: '/about',
            element: <About />
        },
        {
            path: '*',
            element: <Error />
        }
    ]
};

export default MainRoutes;
