import { createSlice } from '@reduxjs/toolkit';
// import { dispatch } from 'store';
import { getNews } from 'utils/apiService';

// initial state
const initialState = {
    newsData: null,
    isLoading: false,
    pageSize: undefined,
    showMoreLoading: false
};

// ==============================|| SLICE - MENU ||============================== //

const slice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        startShowMoreLoading(state) {
            state.showMoreLoading = true;
        },
        setNewsData(state, action) {
            state.isLoading = false;
            state.showMoreLoading = false;
            state.newsData = action.payload.newsData;
            state.pageSize = action.payload.nextPage;
        },
        setNewsLoading(state, action) {
            state.newsLoading = action.payload;
        }
    }
});

export default slice.reducer;
export const { setNewsData, setNewsLoading } = slice.actions;

export function fetchNewData() {
    return async (dispatch, getState) => {
        try {
            const { pageSize, newsData } = getState().news;
            if (!newsData) dispatch(slice.actions.startLoading());
            else dispatch(slice.actions.startShowMoreLoading());
            const data = await getNews(pageSize);
            const objArticles = await data.json();
            const result = {
                newsData: newsData ? [...newsData].concat(objArticles.results) : objArticles.results,
                nextPage: objArticles.nextPage
            };

            dispatch(slice.actions.setNewsData(result));
        } catch (error) {
            console.log(error);
            // dispatch(slice.actions.hasError(error));
        }
    };
}
