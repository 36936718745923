// material-ui
import { styled } from '@mui/material/styles';

// project imports
import AppBar from 'ui-component/extended/AppBar';
import BookAppointment from './BookAppointment';
import ExtraRow from './ExtraRow';
import Feature from './Feature';
import Footer from './Footer';
import Header from './Header';
import KeyFeature from './KeyFeature';
import Subscribe from './Subscribe';
import WhatWeDo from './WhatWeDo';

const HeaderWrapper = styled('div')(({ theme }) => ({
    paddingTop: 30,
    overflowX: 'hidden',
    overflowY: 'clip',
    [theme.breakpoints.down('md')]: {
        paddingTop: 42
    },
    height: '100vh',
    // background: 'url(assets/images/landing/landingPage.jpg) center center no-repeat'
    background:
        'Linear-gradient(rgba(253, 240, 247) , rgba(45,45,45,0.5)),url(assets/images/landing/EPL_TRUCK.jpg) center center no-repeat',
    Zindex: -10
}));

const SecondWrapper = styled('div')(() => ({
    paddingTop: 60
}));

// =============================|| LANDING MAIN ||============================= //

const Landing = () => (
    <>
        <HeaderWrapper id="home">
            <AppBar />
            <Header />
        </HeaderWrapper>
        <SecondWrapper>
            <Feature />
        </SecondWrapper>
        <SecondWrapper>
            <ExtraRow />
        </SecondWrapper>

        <WhatWeDo />

        <KeyFeature />

        <BookAppointment />

        <SecondWrapper>
            <Subscribe />
        </SecondWrapper>
        <Footer />
    </>
);

export default Landing;
