// material-ui
// assets
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AnimateButton from 'ui-component/extended/AnimateButton';

const ExtraRow = () => (
    <Box sx={{ bgcolor: 'grey.100', py: 8 }}>
        <Container>
            <Grid container spacing={7}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h2" component="div">
                        <AutoAwesomeIcon color="secondary" />
                        &nbsp; Get The Best Deals For Your Business
                    </Typography>
                    <Divider />
                    <br />
                    <Typography variant="body2">
                        Whether you use petrol, diesel, or electric, you’ll get advanced analysis or find the right solution with us. Fuel,
                        Repair should save you money and time, so we make getting one as simple as possible
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h2" component="div">
                        <AutoAwesomeIcon color="secondary" />
                        &nbsp; Get Back In Charge
                    </Typography>
                    <Divider />
                    <br />
                    <Typography variant="body2">
                        Our Service allow you to monitor and control fuel spending, preventing unexptected expenses. Set specific spending
                        controls for each driver. You can now control the type of fuel your driver uses and when and where they buy it. This
                        prevents drivers from filling up at expensive stations - saving you money.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h2" component="div">
                        <AutoAwesomeIcon color="secondary" />
                        &nbsp; What We Do?
                    </Typography>
                    <Divider />
                    <br />
                    <Typography variant="body2">
                        We provide insights dashboard with data collected from existing services and AI powered route planner. Customer
                        satisfaction is our top priority. Eco Petroleum is in transportation and logistics industry from long time. With our
                        elite experience we help you to be more efficient with our planner integrated with PC Miler.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h2" component="div">
                        <AutoAwesomeIcon color="secondary" />
                        &nbsp; Control Spending And Save Costs With Advanced Analysis
                    </Typography>
                    <Divider />
                    <br />
                    <Typography variant="body2">
                        Integration with existing dispatch software, to help Analysis with timeseries data. We reduce your time managing
                        expenses and invoices.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h2" component="div">
                        <AutoAwesomeIcon color="secondary" />
                        &nbsp; Tailored To Fit Any Fleet
                    </Typography>
                    <Divider />
                    <br />
                    <Typography variant="body2">
                        Our services can also be tailored to fit your business model, vehicle types, fuel type, and the routes and regions
                        your vehicles cover. Using fuel cards for your transport company allows you to control how much you spend per
                        vehicle, thus streamlining your operations and removing any bottlenecks. Your drivers will never be left stranded at
                        the pump or in the field ever again.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h2" component="div">
                        <AutoAwesomeIcon color="secondary" />
                        &nbsp; How we improve spending
                    </Typography>
                    <Divider />
                    <br />
                    <Typography variant="body2">
                        Eco Petroleum offers all inclusive services for transportation businesses as a straightforward way to manage
                        company-wide spending. You can also control individual driver spending and save time on reports and administration.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <AnimateButton>
                        <Button
                            sx={{ mt: 3 }}
                            onClick={() => {
                                window.Calendly.initPopupWidget({ url: 'https://calendly.com/eplsupport' });
                            }}
                            size="large"
                            variant="contained"
                            color="secondary"
                        >
                            Apply for new account
                        </Button>
                    </AnimateButton>
                </Grid>
            </Grid>
        </Container>
    </Box>
);

export default ExtraRow;
