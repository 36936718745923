/* eslint-disable no-inner-declarations */
// material-ui
import { Container, Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// project imports
import { gridSpacing } from 'store/constant';
import MailerSubscriber from '../maintenance/ComingSoon/ComingSoon1/MailerSubscriber';

const SubscribeWrapper = styled('div')(() => ({
    padding: '100px 0',
    // background: theme.direction === 'rtl' ? darkColorRTL : darkColor
    background: 'linear-gradient(rgba(24,24,50,0.7), rgba(45,45,45,0.4)), url(assets/images/landing/EPL_TSTOP.jpg) center center no-repeat'
}));

const SubscribeCard = styled('div')(({ theme }) => ({
    background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : '#FFFFFF',
    boxShadow: '0px 0px 50px rgba(33, 150, 243, 0.2)',
    borderRadius: '20px',
    padding: '100px 75px',
    [theme.breakpoints.down('md')]: {
        padding: '40px 25px'
    }
}));

// ============================|| LANDING - SUBSCRIBE PAGE ||============================ //

const Subscribe = () => {
    const theme = useTheme();

    return (
        <SubscribeWrapper>
            <Container>
                <Grid container alignItems="center" spacing={gridSpacing}>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{
                            display: { xs: 'none', md: 'block' },
                            textAlign: 'right',
                            [theme.breakpoints.down('lg')]: { textAlign: 'center' }
                        }}
                    >
                        {' '}
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <SubscribeCard>
                            <Grid container spacing={gridSpacing} sx={{ mb: '16px' }}>
                                <Grid item sm={12}>
                                    <Typography
                                        variant="h1"
                                        component="div"
                                        sx={{ [theme.breakpoints.down('md')]: { fontSize: '1.125rem' } }}
                                    >
                                        Subscribe To Our Newsletter
                                    </Typography>
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography variant="body2">
                                        Be the first to learn about our latest news &#38; updates. Don’t worry, we don’t send spam
                                    </Typography>
                                </Grid>
                                <Grid item sm={12}>
                                    <MailerSubscriber />
                                </Grid>
                            </Grid>
                        </SubscribeCard>
                    </Grid>
                </Grid>
            </Container>
        </SubscribeWrapper>
    );
};

export default Subscribe;
