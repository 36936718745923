/* eslint-disable no-else-return */

class Utils {
    static breakPara(data, size) {
        const result = data.slice(0, size);
        return result.concat('...');
    }

    static getWheatherCloudImage(data) {
        if (data === 'overcast clouds') {
            return 'overcast clouds';
        } else if (data === 'broken clouds') {
            return 'broken clouds';
        } else if (data === 'moderate rain') {
            return 'moderate rain';
        } else if (data === 'clear sky') {
            return 'clear sky';
        } else {
            return 'nothing';
        }
    }
}

export default Utils;
