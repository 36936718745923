/* eslint-disable import/first */
/* eslint-disable import/newline-after-import */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
// material-ui

import { Box, Card, CardActions, CardContent, Container, Grid, Typography, useTheme } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';

// assets
import brokenCloud from '../../../assets/images/wheather/brokenClouds.svg';
import clearSky from '../../../assets/images/wheather/clearCloud.svg';
import OverCloud from '../../../assets/images/wheather/overcastClouds.svg';
import rain from '../../../assets/images/wheather/rain.svg';

import { CloudSync, Opacity, WindPower } from '@mui/icons-material';
import { apiKeys } from 'config';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { setWheatherData } from 'store/slices/Wheather';
import SubCard from 'ui-component/cards/SubCard';
import Utils from 'Utils';

// ============================|| LANDING - KEY FEATURE PAGE ||============================ //

// eslint-disable-next-line prefer-const
let lstCity = [
    { name: 'Vancouver', latitude: 49.2827, longitude: 123.1207 },
    { name: 'Edmonton', latitude: 53.5461, longitude: 113.4938 },
    { name: 'Toronto', latitude: 43.6532, longitude: 79.3832 },
    { name: 'Montreal', latitude: 45.5017, longitude: 73.5673 }
];

const KeyFeaturePage = () => {
    const theme = useTheme();
    const { wheatherInfo } = useSelector((state) => state.wheather);

    const getweatherData = async () => {
        try {
            const updatedList = [];
            for (const item of lstCity) {
                const data = await fetch(
                    `https://api.openweathermap.org/data/3.0/onecall?lat=${item.latitude}&lon=${item.longitude}&units=metric&appid=${apiKeys.weatherApiKey}`
                );
                updatedList.push({ ...item, responseData: await data.json() });
            }
            dispatch(setWheatherData(updatedList));
        } catch (ex) {
            console.log(ex);
        }
    };

    useEffect(() => {
        getweatherData();
    }, []);

    // const wheatherInfo = JSON.parse(localStorage.getItem('data'));
    if (wheatherInfo === undefined) return '';

    return (
        <Box sx={{ bgcolor: 'grey.100', py: 8 }}>
            <Container>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} lg={5} md={10}>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Typography variant="h5" color="primary">
                                            Weather Details
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h2">
                                    Get <span style={{ color: theme.palette.secondary.main }}>Weather Info?</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    On the road? Get some useful information about the weather around you so you can drive safer.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={gridSpacing}
                            sx={{ padding: '0px 0px 0px 0px !important' }}
                        >
                            {wheatherInfo?.map((item, index) => (
                                <Grid
                                    item
                                    lg={3}
                                    md={4}
                                    xs={12}
                                    sm={6}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    key={index}
                                >
                                    <SubCard
                                        style={{
                                            width: '270px',
                                            padding: '0px 0px 0px 108px !important'
                                        }}
                                        contentSX={{ p: '15px !important' }}
                                    >
                                        <Grid container sx={{ padding: '0px !important' }}>
                                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                                <Card sx={{ minWidth: 245 }}>
                                                    <Typography variant="subtitle1" color="primary">
                                                        {item.name}
                                                    </Typography>
                                                    <br />
                                                    <CardContent sx={{ padding: '0px !important' }}>
                                                        <Grid container justifyContent="center" alignItems="center">
                                                            <Grid item xs={2} lg={2} md={2} sm={2}>
                                                                {Utils.getWheatherCloudImage(
                                                                    item.responseData.current?.weather[0].description
                                                                ) === 'nothing' ? (
                                                                    <>
                                                                        <img
                                                                            src={OverCloud}
                                                                            alt=""
                                                                            style={{ width: '80px', marginLeft: '18px' }}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {Utils.getWheatherCloudImage(
                                                                            item.responseData.current?.weather[0].description
                                                                        ) === 'overcast clouds' && (
                                                                            <img
                                                                                src={OverCloud}
                                                                                alt=""
                                                                                style={{ width: '80px', marginLeft: '18px' }}
                                                                            />
                                                                        )}
                                                                        {Utils.getWheatherCloudImage(
                                                                            item.responseData.current?.weather[0].description
                                                                        ) === 'broken clouds' && (
                                                                            <img
                                                                                src={brokenCloud}
                                                                                alt=""
                                                                                style={{ width: '80px', marginLeft: '18px' }}
                                                                            />
                                                                        )}
                                                                        {Utils.getWheatherCloudImage(
                                                                            item.responseData.current?.weather[0].description
                                                                        ) === 'moderate rain' && (
                                                                            <img
                                                                                src={rain}
                                                                                alt=""
                                                                                style={{ width: '80px', marginLeft: '18px' }}
                                                                            />
                                                                        )}
                                                                        {Utils.getWheatherCloudImage(
                                                                            item.responseData.current?.weather[0].description
                                                                        ) === 'clear sky' && (
                                                                            <img
                                                                                src={clearSky}
                                                                                alt=""
                                                                                style={{ width: '80px', marginLeft: '18px' }}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={10} lg={10} md={10} sm={10} sx={{ paddingRight: '10px' }}>
                                                                <Typography variant="subtitle2" color="GrayText" textAlign="right">
                                                                    Today
                                                                </Typography>
                                                                <Typography variant="h4" color="initial" textAlign="right">
                                                                    {item.responseData.current?.temp}
                                                                    <sup>O</sup>C
                                                                </Typography>
                                                                <Typography variant="body1" color="initial" textAlign="right">
                                                                    {item.responseData.current?.weather[0].description}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions sx={{ padding: '40px 0px 0px 0px' }}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={4}
                                                                lg={4}
                                                                sm={4}
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                <Opacity style={{ color: '#1ecbe1' }} />
                                                                <Typography variant="subtitle1" color="initial">
                                                                    {item.responseData.current?.humidity}
                                                                    <sup>O</sup>C
                                                                </Typography>
                                                                <Typography variant="caption" color="GrayText">
                                                                    humidity
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={4}
                                                                lg={4}
                                                                sm={4}
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                            >
                                                                <CloudSync style={{ color: '#32cdc7' }} />
                                                                <Typography variant="subtitle1" color="initial">
                                                                    {item.responseData.current?.feels_like}
                                                                    <sup>O</sup>C
                                                                </Typography>
                                                                <Typography variant="caption" color="GrayText">
                                                                    Feels Like
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={4}
                                                                lg={4}
                                                                sm={4}
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                <WindPower style={{ color: '#1d91e2' }} />
                                                                <Typography variant="subtitle1" color="initial">
                                                                    {item.responseData.current?.wind_speed} km/h
                                                                </Typography>
                                                                <Typography variant="caption" color="GrayText">
                                                                    Wind Speed
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </SubCard>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default KeyFeaturePage;
