import { Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from 'store/constant';

// project imports

// assets
import EPL_TSTOP1 from 'assets/images/landing/EPL_TSTOP1.jpg';
import { Box } from '@mui/system';

export default function Service() {
    const theme = useTheme();
    return (
        <Box sx={{ py: 8 }}>
            <Container>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} lg={6} md={6}>
                        <Typography variant="h2" component="div">
                            We Work With <span style={{ color: theme.palette.secondary.main }}>Trusted Brands</span>
                        </Typography>

                        <Typography variant="body2">
                            We have an expansive partner network with coverage in North America. You get access to Repair Services including
                            supermarket sites. Enjoy your trip at different Travel Stops at different motorways, ports, and transport routes
                            across the United States, Canada and Mexico. Long-haul drivers can now spend their time wisely, making
                            deliveries easier than ever.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6}>
                        <img
                            src={EPL_TSTOP1}
                            alt="EPL_TSTOP1"
                            style={{
                                width: '100%',
                                borderRadius: '8px',
                                height: '100%'
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
