/* eslint-disable prettier/prettier */
// require('dotenv').config();

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { LoadingButton, Masonry } from '@mui/lab';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';
import { fetchNewData } from 'store/slices/News';
import SubCard from 'ui-component/cards/SubCard';
import FadeInWhenVisible from '../landing/Animation';
import NewsSkaleton from './NewsSkaleton';
import './style.css';
import imagenf from '../../../assets/images/maintenance/image-not-found.jpeg';

export default function News() {
    const { isLoading, newsData, pageSize, showMoreLoading } = useSelector((state) => state.news);
    const dispatch = useDispatch();
    console.log(showMoreLoading);
    // eslint-disable-next-line no-unused-vars

    useEffect(() => {
        if (!newsData) dispatch(fetchNewData());
    }, [dispatch, newsData]);

    const showMore = () => {
        dispatch(fetchNewData());
    };

    if (isLoading) {
        return (
            <>
                <NewsSkaleton />
                <NewsSkaleton />
                <NewsSkaleton />
            </>
        );
    }
    const hideShowMore = newsData && newsData.length < pageSize * 10;

    return (
        <div>
            <div style={{ marginBottom: '35px' }}>
                <Container>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Masonry columns={{ xs: 1, sm: 2, md: 3, xl: 4 }} spacing={2}>
                                {newsData &&
                                    newsData?.map((item, index) => (
                                        <FadeInWhenVisible key={index}>
                                            <SubCard>
                                                {item.image_url ? (
                                                    <img src={item.image_url} style={{ height: '220px', width: '100%' }} alt="news" />
                                                ) : (
                                                    <img
                                                        src={imagenf}
                                                        style={{ height: '220px' }}
                                                        alt=""
                                                    />
                                                )}
                                                <Box sx={{ p: 1, px: 2 }}>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{
                                                            ':hover': {
                                                                color: 'black',
                                                                cursor: 'pointer',
                                                                textDecoration: 'underline'
                                                            }
                                                        }}
                                                        onClick={() => {
                                                            window.open(item.link);
                                                        }}
                                                    >
                                                        {item.title}
                                                    </Typography>
                                                    <Stack direction="row" justifyContent="space-between">
                                                        <Typography
                                                            variant="subtitle1"
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                mt: 0.5,
                                                                justifyContent: { xs: 'left', md: 'unset' }
                                                            }}
                                                        >
                                                            <AccessTimeIcon fontSize="small" sx={{ mr: 0.5, fontSize: '1rem' }} />
                                                            {moment(item.pubDate).fromNow()}
                                                        </Typography>
                                                    </Stack>

                                                    <Typography
                                                        variant="body2"
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.description
                                                        }}
                                                    />
                                                </Box>
                                            </SubCard>
                                        </FadeInWhenVisible>
                                    ))}
                            </Masonry>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Grid container>
                <Grid item xs={12} style={{ textAlign: 'center' }} sx={{ mb: 2 }}>
                    {!hideShowMore && (
                        <LoadingButton variant="contained" color="secondary" loading={showMoreLoading} onClick={showMore}>
                            Load More
                        </LoadingButton>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}
