import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    subscribeInfo: undefined,
    subscribeLoading: false
};

// ==============================|| SLICE - MENU ||============================== //

const subscribe = createSlice({
    name: 'subscribe',
    initialState,
    reducers: {
        setSubscribeData(state, action) {
            state.subscribeInfo = action.payload;
        },
        setSubscribeLoading(state, action) {
            state.subscribeLoading = action.payload;
        }
    }
});

export default subscribe.reducer;
export const { setSubscribeData, setSubscribeLoading } = subscribe.actions;
