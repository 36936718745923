// routing
import Routes from 'routes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import ThemeCustomization from 'themes';
import Snackbar from 'ui-component/extended/Snackbar';
import Locales from 'ui-component/Locales';

const App = () => (
    <ThemeCustomization>
        <Locales>
            <NavigationScroll>
                <Routes />
                <Snackbar />
            </NavigationScroll>
        </Locales>
    </ThemeCustomization>
);

export default App;
