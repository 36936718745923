// material-ui
import { Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import { gridSpacing } from 'store/constant';
import SubCard from 'ui-component/cards/SubCard';
import Avatar from 'ui-component/extended/Avatar';
import FadeInWhenVisible from './Animation';

// assets
import {
    AccountBalanceWalletRounded,
    AddShoppingCartOutlined,
    CloudCircle,
    DiscountRounded,
    OnlinePrediction,
    PriceChangeRounded,
    PublicOutlined,
    ReportRounded
} from '@mui/icons-material';

// =============================|| LANDING - FEATURE PAGE ||============================= //

const featuresInfo = [
    {
        icon: <ReportRounded fontSize="large" />,
        heading: 'Accurate Reporting',
        desc: 'Get exact reports of every mile of every journey your drivers make. Login to get started.'
    },
    {
        icon: <DiscountRounded fontSize="large" />,
        heading: 'API Integration',
        desc: 'Save development time with our ready to go API Integration across different platforms'
    },
    {
        icon: <PriceChangeRounded fontSize="large" />,
        heading: 'Savings with Machine learning',
        desc: 'Recommendations for efficient trips based on real-time data from trusted channels'
    },
    {
        icon: <ReportRounded fontSize="large" />,
        heading: '24/7 Customer Service',
        desc: 'Come rain or sunshine, our support staff will be available to attend to you and your drivers.'
    },
    {
        icon: <OnlinePrediction fontSize="large" />,
        heading: 'Online Portal',
        desc: 'Log in to your portal to monitor fleet operations and expenses with less paperwork and less reconciling.'
    },
    {
        icon: <AccountBalanceWalletRounded fontSize="large" />,
        heading: 'Control Your Expenditure',
        desc: 'Manage driver expenses and habits, limit misuse, and improve your bottom line in one fell swoop.'
    },
    {
        icon: <PublicOutlined fontSize="large" />,
        heading: 'Universal Acceptance',
        desc: 'Utilize services anywhere in the United States, Canada and all of North America without hassles.'
    },
    {
        icon: <AddShoppingCartOutlined fontSize="large" />,
        heading: 'Fast Card Service',
        desc: 'Apply for any service in a matter of minutes, fund instantly, and start saving in as little as one day.'
    },
    {
        icon: <CloudCircle fontSize="large" />,
        heading: 'Weather Reports',
        desc: 'Your drivers get access to comprehensive weather reports so they can stay longer on the road safely.'
    },
    {
        icon: <AddShoppingCartOutlined fontSize="large" />,
        heading: 'Tax Reports',
        desc: 'Capture and prepare tax reports, IRP, IFTA, etc., all from the online portal save time.'
    }
];

const FeaturePage = () => {
    const theme = useTheme();
    return (
        <>
            <Container>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} lg={6} md={10}>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Typography variant="h5" color="primary">
                                            Top Features
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography variant="h2" component="div">
                            Why Choose <span style={{ color: theme.palette.secondary.main }}>Eco Petroleum </span> ?
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={gridSpacing} sx={{ textAlign: 'center' }}>
                            {featuresInfo.map((item, index) => (
                                <Grid item md={4} lg={3} sm={6} key={index}>
                                    <FadeInWhenVisible>
                                        <SubCard>
                                            <Grid
                                                container
                                                justifyContent="center"
                                                spacing={2}
                                                sx={{ height: '270px', maxHeight: '100%', p: 1.5 }}
                                            >
                                                <Grid item>
                                                    <Avatar
                                                        size="xl"
                                                        variant="rounded"
                                                        sx={{
                                                            background:
                                                                theme.palette.mode === 'dark'
                                                                    ? theme.palette.dark[900]
                                                                    : theme.palette.secondary.light,
                                                            color: theme.palette.primary.main,
                                                            marginTop: 1
                                                        }}
                                                    >
                                                        {item.icon}
                                                    </Avatar>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h3" color="secondary">
                                                        {item.heading}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body2">{item.desc}</Typography>
                                                </Grid>
                                            </Grid>
                                        </SubCard>
                                    </FadeInWhenVisible>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default FeaturePage;

// <Grid item xs={12}>
//                                 <Typography variant="h2" component="div">
//                                     What <span style={{ color: theme.palette.secondary.main }}>Eco Petroleum</span> brings to you?
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <Typography variant="body2">
//                                     Eco Petroleum offers prepaid fuel cards for transportation businesses as a straightforward way to manage
//                                     company-wide spending. You get discounts on fuel at thousands of network stations all over North
//                                     America. You can also control individual driver spending and save time on reports and administration
//                                 </Typography>
//                             </Grid>
